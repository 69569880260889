// type   '商品类型(1知识套餐课程  2直播课程  3录播课程 4公开课程 5资料包)',
export const showTypeEnum = [
  '',
  '知识套餐',
  '直播',
  '录播',
  '公开课',
  '资料包',
  '试卷包',
  '音频',
  '图文',
  '专栏',
  '面授',
  '训练营',
  '试卷',
  '',
  '教材',
  '虚拟考场',
  '课程方向'
]
export const showTypeEnums = [
  '',
  '知识套餐',
  '直播课',
  '录播课',
  '公开课',
  '资料包',
  '试卷包',
  '音频',
  '图文',
  '专栏',
  '面授',
  '训练营',
  '试卷',
  '',
  '教材',
  '虚拟考场',
  '课程方向'
]
export const liveEnum = [
  '',
  '直播',
  '录播',
  '音频课',
  '图文课',
  '面授',
  '课程方向'
]
export const liveEnums = [
  '',
  '直播',
  '录播',
  '音频',
  '图文',
  '面授',
  '课程方向'
]
// type   '商品类型(1知识套餐课程  2直播课程  3录播课程 4公开课程 5资料包)',
export const IsLive = ['直播未开始', '播放中', '回放生成中', '回放']
export const IsLiveClick = ['直播未开始', '观看直播', '回放生成中', '立即观看']
export const roomType = ['', '公开', '限邀请']
export const roomStatu = ['', '未开始', '进行中', '已结束']
export const queType = [
  '单选题',
  '多选题',
  '判断题',
  '填空题',
  '简答题',
  '组合题',
  '不定项选择题'
]
export const queTypeNum = [
  '一',
  '二',
  '三',
  '四',
  '五',
  '六',
  '七'
]
/* 星期 */
export const weekEnum = [
  '星期日',
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
  '星期今'
]
export const Courier = {
  YTO: require('@/assets/img/textmall/yuantong.png'),
  百世: require('@/assets/img/textmall/baishi.png'),
  STO: require('@/assets/img/textmall/yto.png')
}

/* 星期 */
export const weekZhouEnum = [
  '周一',
  '周二',
  '周三',
  '周四',
  '周五',
  '周六',
  '周日'
]
// 训练营=
export const signStatus = ['', '未开始', '我要报名', '已结束']
// questionTypeIcon
export const questionTypeIcon = [
  require('@/assets/img/partner/radio.png'),
  require('@/assets/img/partner/checkbox.png'),
  require('@/assets/img/partner/determine.png'),
  require('@/assets/img/partner/subjectivity.png'),
  require('@/assets/img/partner/pack.png'),
  require('@/assets/img/partner/group.png'),
  require('@/assets/img/partner/indefinite.png')
]
